$dark: #15141a;
$dark-gray: #23222b;
$light: #fbfbfe;
$mid-blue: #0554f2;
$light-blue: #196eff;
$dark-blue: #013cc9;
$transition: all 0.3s ease-in-out;

.hero-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: $light;
  background-color: $dark !important;
  .hero-text {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    border-left: 3px solid $light-blue;
    padding: 0 24px;
    h1 {
      font-size: 3rem;
      font-weight: 900;
      margin: 0 0 20px;
      span {
color: $light-blue;
      }
    }
    p {
      font-size: 1.2rem;
      margin: 0 0 30px;
    }
    button {
      color: $light;
      border: 2px solid $light;
      outline: none;
      width: 200px;
      transition: $transition;
      &:hover {
        color: $light!important;
        background-color: $light-blue!important;
        transform: scale(1.03);
        border: 2px solid $light!important;
      }
      &:focus {
        outline: none;
      }
    }
  }
}



.follow-icons {
  .icon {
    font-size: 3rem;
    color: $light;
    padding: 0 3px;
    transition: $transition;
    &:hover {
      color: $light-blue !important;
        background-color: $light !important;
        transform: scale(1.03);
        border: 2px solid $light-blue !important;
    }
  }
}

// projects section

// .sub-header {
//   h2 {
//     font-size: 2.5rem;
//     font-weight: 900;
//   }
// }

// .button-group {
//   button {
//     background-color: $light-blue;
//     outline: none;
//     border: 2px solid $mid-blue;
//     &:link {
//       background-color: $light;
//       color: $light-blue;
//       border: 2px solid $mid-blue;
//     }
//     &:hover {
//       background-color: $light;
//       color: $light-blue;
//       border: 2px solid $mid-blue;
//     }
//     &:focus {
//       background-color: $light;
//       color: $light-blue;
//       border: 2px solid $mid-blue;
//       outline: none;
//     }
//     &:active {
//       background-color: $light;
//       color: $light-blue;
//       border: 2px solid $mid-blue;
//       outline: none;
//     }
//   }
// }

// .front-visible {
//   background-color: $light-blue;
//   opacity: 1;
// }

// .no-visible {
//   opacity: 0;
// }

// .project-box {
//   height: 704px;
//   .image-container {
//     overflow: hidden;
//     height: 360px;
//     img {
//       transition: $transition;
//     }
//   }
//   &:hover img {
//     transform: scale(1.05);
//   }
// }

// NEW PROJECT ITEM

.project-item {
  margin: 4rem 0;
  .project-image-container {
    position: relative;
    height: 600px;
    .project-img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      object-fit: cover;
      z-index: 1;
    }
    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgb(169, 183, 255);
      background: linear-gradient(165deg, rgba(169, 183, 255, 0.709) 0%, rgba(1, 61, 201, 0.884) 90%);
      border-radius: 5px;
      z-index: 5;
      top: 0;
      left: 0;
    }
  }
  .project-description {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    .project-card {
      background-color: #e7e9eb;
      min-height: 40%;
      height: auto;
    }
    .odd {
      margin-left: -200px;
    }
    .even {
      margin-right: -200px;
    }
  }
    transition: all 0.3s ease-in-out;
  &:hover .overlay {
    background: transparent;
  }
   
}

//  skills

.skills-container {
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  .skill-box {
    width: 100px;
    height: 100px;
    margin: 20px;
    padding: 15px;
    display: flex;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &:hover img {
      transform: translateY(0);
      filter: drop-shadow(0 0 0.3rem #72b1ec);
    }
    figcaption {
      opacity: 0;
      color: $dark;
      transition: all 0.6s ease-in-out;
      transform: translateY(-20px);
    }
    .skills-img {
      width: 40px;
      min-width: 40px;
      height: 40px;
      min-height: 40px;
      transform: translateY(10px);
      transition: all 0.6s ease-in-out;
    }
    &:hover figcaption {
      opacity: 100%;
      animation: fadeIn 2s;
      -webkit-animation: fadeIn 1s;
      -moz-animation: fadeIn 1s;
      -o-animation: fadeIn 1s;
      -ms-animation: fadeIn 1s;
      transform: translateY(0);
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

//  Experience

.experience-box {
  border-left: 5px solid $light-blue;
  padding: 0 24px;
  position: relative;
  span {
    color: gray;
  }
}

//  Connect

.contact {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.connect {
  .connect-image-col {
    display: flex;
    justify-content: center;
    .connect-img {
      width: 250px;
      img {
        width: 100%;
      }
    }
  }
  .connect-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    a {
      font-size: 3rem;
      margin: 15px;
      padding: 10px;
      color: $light-blue;
      transition: $transition;
      &:hover {
        color: $mid-blue;
        transform: scale(1.1);
      }
    }
  }
}

// RESPONSIVE DESIGN

@media only screen and (max-width: 767px) {
  .project-item {
    .project-image-container {
      height: 400px;
      .project-img {
        height: 100%;
        border-radius: 0;
      }
      .overlay {
        height: 100%;
        border-radius: 0;
      }
    }
    .project-description {
      .project-card {
        background-color: #e7e9eb;
        height: auto;
        border-radius: 0;
      }
      .odd {
        margin-left: 0 !important;
      }
      .even {
        margin-right: 0 !important;
      }
    }
  }
}
